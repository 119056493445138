import { NextRouteItem } from 'app/core/types/route.type';
import { NextRouteProps } from 'app/core/enums/route.enum';
import { NavItemId } from 'app/shared/enums/navigation.enum';
import { NextPermissionId } from 'app/core/enums/permission.enum';
import { GeneralMaintenanceRoutingConfig } from '@pages/general-maintenance/general-maintenance.route.config';

export class UserRouteConfig {
  static readonly mainPath = 'user-manager';
  static readonly listConfig: NextRouteItem = {
    [NextRouteProps.ID]: NavItemId.USER,
    label: 'USER.MANAGER.TITLE',
    [NextRouteProps.PATH]: UserRouteConfig.mainPath,    
    [NextRouteProps.PARENT]: GeneralMaintenanceRoutingConfig.mainRoute,
    [NextRouteProps.PERMISSION]: [NextPermissionId.USERS]
  };
}
